<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="message" permission="2" error>
            <div class="flix-row flix-form-group">
              <div class="flix-col-md-6 flix-col-md-flix-offset-3">
                <div class="flix-form-group">
                  <chat :user="$route.params.user" :form="$route.params.form" :entryID="$route.params.entryID"></chat>
                </div>
              </div>
            </div>
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    chat () { return import('@/components/chat/userChat') }
  }
}
</script>
